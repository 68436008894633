.full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

.hero-box {
  min-height: 200px;

  @include media-breakpoint-up(xl) {
    padding-right: 4rem;
  }
}

h1.hero-text {
  font-size: 2.5rem;

  @include media-breakpoint-up(md) {
    font-size: 3.5rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 4.687rem;
  }
}

#text-secondary {
  color: map-get($theme-colors, primary);
}

.home-hero-content {
  height: calc(100vh - 4rem);
}

h1.project-heading {
  font-size: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 2.3rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3.125rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 3.125rem;
  }
}

h1.hero-text-60 {
  font-size: 2.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 3.75rem;
  }
}

.top-content {
  background-color: map-get($theme-colors, secondary);
  color: white;

  /* padding-top: 12%; */

  p {
    color: white;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}

.row.mid {
  padding-top: 100px;
}

.pattern {
  height: 4rem;
  top: -1px;
  z-index: -1;
  position: relative;
}

.pattern.grey {
  background-color: map-get($theme-colors, light-grey);
}

.z-1 {
  z-index: 1;
}

.top-1 {
  top: 1px;
}

.home-content {
  padding-top: 100px;
  margin-top: -1px;
}

.d-green {
  background-color: map-get($theme-colors, secondary);
  padding: 0;
  color: white;
}

.left,
.right {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 100%;
  }
}

.featured-text {
  background-color: map-get($theme-colors, secondary);
  text-align: center;
  align-self: center;
  height: 100%;
}

h3.article-title {
  color: map-get($theme-colors, secondary);
  font-family: "Bitter", sans-serif;
  font-size: 1.875rem;

  @include media-breakpoint-down(lg) {
    font-size: 1.4rem;
  }
}

h3.single-article-title,
h3.project-title {
  font-size: 1.875rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;
  }
}

.featured:hover .featured-text {
  background-color: map-get($theme-colors, t-black);
  flex-basis: 105%;
}

.box {
  height: 100%;
  justify-content: space-evenly;
  padding-left: 1rem;
  padding-right: 1rem;

  @include media-breakpoint-down(sm) {
    height: 300px;
  }
}

p.feature-title {
  color: white;
  font-size: 1.5625rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.image-box {
  width: 50%;

  .feature-right {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.article-img-container {
  position: relative;
  margin: auto;
  overflow: hidden;
  padding-top: 83.25%;
}

.image-wrapper img {
  transition: all 0.5s;
}

.article-img-container img {
  transition: all 0.5s;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.wide .article-img-container img {
  transform: scale(1.1);
  transition: all ease 0.5s;
}

.wide:hover .article-img-container img {
  transform: scale(1);
  transition: all ease 0.5s;
}

.featured:hover .image-wrapper img {
  transform: scale(1.1);
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.image-wrapper.work {
  padding-top: 63%;
}

.image-wrapper.article {
  padding-top: 83.25%;
}

.image-wrapper.project-single-img {
  padding-top: 75.25%;
}

img.image-responsive {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.article-box:hover .image-wrapper img,
.article-container:hover .image-wrapper img {
  transform: scale(1.1);
}

.feature-img {
  background-size: cover;
  background-position: 50% 50%;
  height: 22.5vw;
  max-width: 680px;

  @include media-breakpoint-down(lg) {
    height: 34vw;
  }

  @include media-breakpoint-down(md) {
    height: 32vw;
  }

  /* width: 100%; */
}

h2.feature-heading {
  font-family: "Bitter", sans-serif;
  line-height: 1.4;
  font-size: 2.5rem;

  @include media-breakpoint-down(lg) {
    font-size: 2rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }
}

/* .feature-right {
  height: 350px;
} */

.bottom-page {
  padding-bottom: 10rem;
}

h2.feature-heading:hover {
  color: white;
}

a.article-link:hover {
  text-decoration: none;
}

p.text-link {
  overflow: hidden;
  text-align: center;
  color: map-get($theme-colors, secondary);
  font-size: 18px;
}

p.text-link::before,
p.text-link::after {
  background-color: #707070;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

p.text-link::before {
  right: 3rem;
  margin-left: -50%;
}

p.text-link::after {
  left: 3rem;
  margin-right: -50%;
}

.article-container {
  justify-content: space-between;
}

.article-img {
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 70%;
}

.text-box {
  padding: 2rem;
}

.article-box {
  background-color: white;
}

.article-box:hover,
.article-container:hover {
  /* background-color: map-get($theme-colors, t-black); */

  color: white;
}

.article-box .text-box,
.article-container .text-box {
  background-color: white;
  position: relative;
  transition: top ease 0.5s;
  top: 0;
}

h2.section-heading {
  color: map-get($theme-colors, secondary);
  font-size: 2.125rem;

  @include media-breakpoint-up(lg) {
    font-size: 3.125rem;
  }

  @extend .niveau-grotesk;
}

/* About */

.about .top-content {
  background-color: map-get($theme-colors, primary);
}

.about-content {
  background-color: map-get($theme-colors, t-light-grey);
  margin-top: -1px;
  padding-bottom: 5rem;
}

a.f-link {
  color: white;
}

.featured:hover a.f-link {
  text-decoration: none;
}

.featured:hover a.f-link h2 {
  color: white;
}

.articles-top-content,
.work-top-content {
  height: 90vh;

  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }

  @include media-breakpoint-up(md) {
    height: 50vh;
  }

  @include media-breakpoint-up(lg) {
    height: 56vh;
  }

  @include media-breakpoint-up(xl) {
    height: 80vh;
  }
}

.page-section-work {
  margin-top: -170px;
  padding-bottom: 10rem;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.text-box p {
  font-size: 18px;
}

.contact-address p {
  color: white;
  font-size: 1.5625rem;
}

.single article p {
  font-size: 1rem;
}

.icon {
  display: inline-block;
  width: 41px;
  height: 27px;
  background-repeat: no-repeat;
}

.icon-eye {
  background-image: url("../images/level-eye-icon.svg");
}

.featured:hover a.f-link .icon-eye {
  background-image: url("../images/level-eye-icon-blue.svg");
}

.icon-eye-blue {
  background-image: url("../images/level-eye-icon-blue.svg");
}

a.f-link:hover .icon-eye-blue {
  background-image: url("../images/level-eye-icon.svg");
}

.icon-eye-black {
  background-image: url("../images/level-eye-icon-black.svg");
}

a.a-link {
  color: map-get($theme-colors, secondary);
  text-decoration: none;
}

.desc-box {
  padding-left: 1rem;
  padding-right: 1rem;

  @include media-breakpoint-up(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 4rem;
    padding-right: 5rem;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

p.about-text {
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

h3.name {
  @extend .bitter-font;

  font-size: 1.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
}

h4.team-role {
  font-size: 1rem;
  font-weight: 600;

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

p.team-desc {
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

.about-featured-box {
  margin-top: 5rem;
  padding: 6rem 0 5rem 4rem;
}

.team-img {
  background-size: cover;
  background-position: 50% 50%;

  /* height: 290px; */
  max-width: 678px;
  padding-top: 65%;

  @include media-breakpoint-down(lg) {
    padding-top: 65%;
  }

  @include media-breakpoint-down(md) {
    padding-top: 65%;
  }
}

.contact-content {
  height: calc(100vh - 4rem);

  @include media-breakpoint-down(sm) {
    padding-top: 150px;
    height: 100vh;
  }
}

.contact-content .container {
  height: 100%;
}

p.text-content,
p.phone {
  color: white;
}

a.contact-email,
p.contact-phone {
  color: white;
  font-size: 1.5625rem;
}

a.contact-email:hover {
  text-decoration: none;
}

body.contact {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.background {
  background-color: map-get($theme-colors, primary);
}

.contact-box {
  color: white;
}

p.t-black,
h1.t-black {
  color: map-get($theme-colors, t-black);
}

a.map-link {
  color: map-get($theme-colors, t-black);
  text-transform: uppercase;
  font-size: 1.5625rem;
  font-weight: 600;
}

a.map-link:hover {
  text-decoration: none;
}

.icon-eye-black.contact {
  position: relative;
  top: 5px;
}

// Articles //

.page-section-articles {
  margin-top: -200px;

  @include media-breakpoint-down(md) {
    margin-top: -150px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    padding-bottom: 100px;
  }
}

.pattern.white {
  z-index: 0;
}

.pattern.top-0 {
  top: 0;
}

.small > a > img.wp-post-image {
  width: 100%;
  height: 17vw;
  object-fit: cover;

  @include media-breakpoint-down(lg) {
    height: 25vw;
  }

  @include media-breakpoint-down(md) {
    height: 55vw;
  }

  @include media-breakpoint-down(sm) {
    height: 60vw;
  }
}

.wide .text-box {
  @include media-breakpoint-up(md) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.wide > a {
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.wide > a.a-link {
  transition: all ease 0.5s;
}

.wide:hover > a.a-link {
  @include media-breakpoint-up(xl) {
    margin-left: -20px;
    z-index: 2;
    transition: all 0.5s ease;
  }
}

.wide > a > img.wp-post-image {
  width: 680px;
  height: 30vw;

  @include media-breakpoint-down(lg) {
    height: 55vw;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    height: 60vw;
  }
}

.wide > a .text-box {
  width: 100%;

  p {
    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }
}

.small > .article-img-container img.wp-post-image {
  transform: scale(1);
}

.small:hover > .article-img-container img.wp-post-image {
  transform: scale(1.1);
}

.featured:hover a.f-link p {
  color: map-get($theme-colors, secondary);
}

.article-box:hover .text-box,
.article-container:hover .text-box {
  /* background-color: map-get($theme-colors, t-black); */
  top: -20px;
}

.wide a.a-link:hover h3 {
  color: map-get($theme-colors, secondary);
}

img.work-project-img {
  width: 100%;
  max-height: 430px;
  object-fit: cover;

  @include media-breakpoint-down(lg) {
    max-height: 350px;
  }

  @include media-breakpoint-down(md) {
    max-height: 300px;
  }
}

.single .hero-image {
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;

  @include media-breakpoint-down(md) {
    height: 60vh;
  }

  @include media-breakpoint-down(lg) {
    height: 60vh;
  }

  @include media-breakpoint-down(sm) {
    height: 90vh;
  }
}

.layer {
  background-color: rgba(23, 3, 3, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
}

.arrow {
  z-index: 1;
  height: 72px;
  width: 72px;
  background-repeat: no-repeat;
}

p.project-title,
h1.project-heading {
  color: white;
  z-index: 2;
}

.pattern.white.image-overlay {
  position: absolute;
  top: calc(100% - 4rem);
  height: 4rem;
  width: 100%;
}

.post-heading {
  height: 18vh;

  @include media-breakpoint-down(lg) {
    height: 45vh;
  }
}

.project-content {
  height: 100%;

  .box {
    color: white;
    text-align: center;
    height: 50%;
    align-items: center;
    justify-content: space-around;

    h1 {
      font-family: "Bitter", sans-serif;
      font-size: 3.1875rem;

      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
      }
    }
  }
}

h1.article-heading {
  font-family: "Bitter", sans-serif;
}

h3.quote {
  @extend .article-title;
}

h3.section-title {
  @extend .article-title;
}

p.name {
  text-transform: uppercase;
  font-size: 1rem;
}

p.service-title {
  color: map-get($theme-colors, primary);
  font-size: 1rem;
}

p.services-text {
  font-size: 1rem;
}

ul.services-list {
  list-style-type: none;
}

.gallery {
  display: flex;
  flex-flow: unset;
}

.item {
  margin-left: 5px;
  margin-right: 5px;

  img.gallery-img {
    width: 100%;
  }
}

img.feature-right {
  @include media-breakpoint-down(sm) {
    height: 350px;
  }
}

.small.article-container .article-img-container img.wp-post-image {
  transform: scale(1);
}

.small.article-container:hover .article-img-container img.wp-post-image {
  transform: scale(1.1);
}

.entry-content {
  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}
