@import url("https://fonts.googleapis.com/css?family=Bitter:400,400i|Open+Sans:300,600&display=swap");
@import url("https://use.typekit.net/aam3xdq.css");

html {
  @media (min-width: 768px) {
    font-size: 12px;
  }

  @media (min-width: 992px) {
    font-size: 14px;
  }

  @media (min-width: 1300px) {
    font-size: 14px;
  }

  @media (min-width: 1636px) {
    font-size: 16px;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

footer {
  margin-top: auto;
}

h1 {
  font-family: "niveau-grotesk", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 3rem;

  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}

p {
  font-size: 1.25rem;
  color: map-get($theme-colors, t-black);
}

.container {
  max-width: 1636px;
  width: 100%;
}

.b-grey {
  background-color: map-get($theme-colors, t-light-grey);
}

.b-white {
  background-color: white;
}

.component-spacing {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.secondary {
  font-size: 1.5625rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}
