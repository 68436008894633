footer.footer-content {
  background-color: map-get($theme-colors, secondary);
  color: white;
}

h3.footer-heading {
  @extend .niveau-grotesk;

  font-size: 2.5rem;
}

.pattern.footer {
  top: 0;
}

p.text-content {
  font-size: 1.25rem;
}

p.phone,
p.email {
  @extend .niveau-grotesk;

  font-size: 2.5rem;
  margin-bottom: 0;
}

p.email {
  color: map-get($theme-colors, primary);
}

.contact-footer {
  margin-top: auto;
}

a.email:hover {
  text-decoration: none;
}
