/*
.banner .nav li {
  @extend .nav-item;
}

.banner .nav a {
  @extend .nav-link;
} */

body.home header {
  background-color: map-get($theme-colors, secondary);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 108px;
  width: 100%;
  position: fixed;
  z-index: 1000;

  .brand-logo {
    background-image: url("../images/level-logo-white.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;
  }
}

body.work header {
  background-color: white;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 1000;

  .brand-logo {
    background-image: url("../images/level-logo.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;
  }
}

body.about header,
body.contact header {
  background-color: map-get($theme-colors, primary);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 1000;

  .brand-logo {
    background-image: url("../images/level-logo-white.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;
  }
}

body.single header,
body.articles header {
  background-color: transparent;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 1000;

  .brand-logo {
    background-image: url("../images/level-logo.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;
  }
}

body.single-project header {
  background-color: transparent;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 1000;

  .brand-logo {
    background-image: url("../images/level-logo-white.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;
  }
}

.overlay-logo {
  background-image: url("../images/level-logo-white.svg");
  display: inline-block;
  width: 127px;
  height: 30px;
  background-repeat: no-repeat;
  left: 5%;
  position: fixed;
  top: 5%;
  z-index: 20;
}

body.home header.sticky,
body.about header.sticky,
body.contact header.sticky {
  height: 100px;
  background-color: white;
  z-index: 5;
  position: fixed;

  @include media-breakpoint-down(sm) {
    top: 0;
  }

  .brand-logo {
    background-image: url("../images/level-logo.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;
  }
}

body.work header.sticky,
body.articles header.sticky {
  height: 100px;
  background-color: map-get($theme-colors, secondary);
  z-index: 5;
  position: fixed;

  @include media-breakpoint-down(sm) {
    top: 0;
  }

  .brand-logo {
    background-image: url("../images/level-logo-white.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;

    &.overlay.open {
      background-image: url("../images/level-logo-white.svg");
    }
  }
}

body.single header.sticky {
  height: 100px;
  background-color: white;
  z-index: 5;
  position: fixed;

  @include media-breakpoint-down(sm) {
    top: 0;
  }

  .brand-logo {
    background-image: url("../images/level-logo.svg");
    display: inline-block;
    width: 127px;
    height: 30px;
    background-repeat: no-repeat;
  }

  &.overlay.open {
    background-image: url("../images/level-logo-white.svg");
  }
}

.logo-box {
  height: 100px;
}

a.brand {
  position: fixed;
  z-index: 1000;
}

// Top Hamburger Menu //

$color-background: #fff;
$color-main: #252525;
$color-active: #fff;
$color-link: #fff;

$button-height: 27px;
$button-width: 32px;

.container-nav {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;

  /* top: 40%; */
  left: 0;
  margin: 0 auto;

  p {
    font-size: 20px;
  }
}

.button_container {
  position: absolute;
  top: 31%;
  right: 7%;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;

  @include media-breakpoint-up(md) {
    top: 31%;
    right: 10%;
  }

  @include media-breakpoint-up(lg) {
    right: 2%;
  }

  @include media-breakpoint-up(xl) {
    top: 31%;
  }

  &:hover {
    opacity: 0.7;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: $color-active;
    }

    .middle {
      opacity: 0;
      background: $color-active;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: $color-active;
    }
  }

  span {
    background: $color-link;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}

header.sticky .button_container {
  @include media-breakpoint-down(sm) {
    top: 31%;
  }
}

header.sticky .button_container span {
  background-color: map-get($theme-colors, secondary);
}

body.single-project .button_container span {
  background-color: white;
}

body.work .button_container span,
body.articles .button_container span,
body.single .button_container span {
  background-color: map-get($theme-colors, secondary);
}

body.work header.sticky .button_container span,
body.articles header.sticky .button_container span {
  background-color: white;
}

body.single header.sticky .button_container span {
  background-color: map-get($theme-colors, secondary);
}

body.single-project.menu-open header.sticky .button_container span {
  background-color: white;
}

.overlay {
  position: fixed;
  background: $color-main;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 20;
  }
}

.brand-logo .overlay.open {
  background-image: url("../images/level-logo-white.svg");
}

.overlay nav {
  /* position: relative;
  top: 20%;
  width: 50%; */

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    height: 100%;
    display: block;

    li {
      display: block;
      height: 25%;
      height: calc(100% / 4);
      min-height: 50px;
      opacity: 0;
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.35s;

      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.45s;
      }

      &:nth-of-type(4) {
        animation-delay: 0.5s;
      }
    }
  }
}

body.home.menu-open header .brand-logo,
body.work.menu-open header .brand-logo,
body.articles.menu-open header .brand-logo,
body.single.menu-open header .brand-logo,
body.about.menu-open header .brand-logo,
body.contact.menu-open header .brand-logo {
  background-image: url("../images/level-logo-white.svg");
}

.container-nav a {
  display: inline-block;
  position: relative;
  text-align: center;
  color: $color-main;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  top: 5px;
}

.container-nav a::after {
  content: "";
  position: absolute;
  background: $color-main;
  height: 2px;
  width: 0%;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  transition: 0.35s ease;
}

.overlay a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  transition: 0.35s;
}

.container-nav a:hover::after,
.container-nav a:focus::after,
.container-nav a:active::after {
  width: 100%;
}

.overlay a {
  display: block;
  position: relative;
  color: $color-link;
  text-decoration: none;
  overflow: hidden;
  font-family: "Bitter", sans-serif;
  text-align: right;
  font-size: 3.7rem;

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }
}

.overlay a:hover::after,
.overlay a:focus::after,
.overlay a:active::after {
  width: 100%;
}

[aria-current]:not([aria-current="false"]) {
  color: map-get($theme-colors, secondary);
}

ul#menu-primary-navigation a:hover {
  color: map-get($theme-colors, secondary);
  transition: 0.3s ease;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

.hover-container {
  font-size: 3.125rem;
  color: map-get($theme-colors, secondary);
  text-align: left;
}

.col-8.offset-2.d-flex.push-top {
  flex-direction: row;
  justify-content: space-between;
}

.push-top {
  top: 25vh;
}

.nav-phone,
.nav-email {
  color: map-get($theme-colors, secondary);
  font-size: 1.25rem;
}

.contact-info p {
  line-height: 1;
}

a.email {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

a[href^=tel] {
  @extend .nav-phone;

  text-decoration: inherit;
  color: inherit;
}
