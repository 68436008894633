/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$theme-colors: (
  primary: #00afba,
  secondary: #097282,
  t-black: #252525,
  t-light-grey: #f0efef
);

.niveau-grotesk {
  font-family: "niveau-grotesk", sans-serif;
  font-weight: 900;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

.bitter-font {
  font-family: "Bitter", sans-serif;
  color: map-get($theme-colors, secondary);
}

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;
